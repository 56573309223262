@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

/* 
FONTS
    font-family: 'Poppins', sans-serif;
    font-family: 'Oswald', sans-serif;
*/

.menu-1-outer-container {
    max-width: 1150px;
    margin: 0 auto;
    padding: 4%;
    /* position: fixed; */
}

.menu-1-inner-container {}

.menu-1-mobile-menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-1-mobile-menu-logo {
    width: 15%;
}

.menu-1-mobile-menu-hamburger {
    width: 10%;
}

.rotate-90 {
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);
}

.mobile-menu-links-container {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    /* background-color: #ecebe8; */
    background-color: #A63333;
}

.mobile-menu-exit-icon {
    position: absolute;
    width: 15%;
    left: 50%;
    transform: translateX(-50%);
    top: 15%;
}

.mobile-menu-links {
    font-family: 'Oswald', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 0;
}

.mobile-menu-link {
    font-size: 1.5rem;
    /* color: grey; */
    color: #fff;
    text-decoration: none;
    margin: 15% 0 0;
}

.mobile-menu-link:first-child {
    margin: 0;
}

.menu-1-links-container {
    display: none;
    justify-content: space-between;
    align-items: center;
}

.menu-1-menu-logo-container {
    width: 25%;
}

.menu-1-menu-logo {
    width: 35%;
}

.menu-1-links {
    display: flex;
    justify-content: space-between;
    font-size: .9rem;
    white-space: nowrap;
}

.menu-1-link {
    font-family: 'Oswald', sans-serif;
    /* color: gray; */
    color: #474747;
    margin: 0 30px 0 0;
    letter-spacing: .6px;
    text-decoration: none;
}

.menu-1-link:last-child {
    margin: 0;
}

@media (min-width: 600px) {
    .menu-1-mobile-menu-container {
        display: none;
    }

    .menu-1-links-container {
        display: flex;
        justify-content: center;
    }

    .menu-1-link {
        display: flex;
        align-items: center;
    }

    .menu-1-menu-logo {
        width: 100%;
        max-width: 62px;
        max-height: 62px;
    }

    .menu-1-menu-desktop-logo {
        width: 4rem;
    }
}

@media (min-width: 700px) {
    .menu-1-outer-container {
        padding: 3%;
    }
}

@media (min-width: 900px) {
    .menu-1-outer-container {
        padding: 1.5% 3%;
    }

    .menu-1-link {
        margin: 0 43px 0 0;
    }
    
    .menu-1-link:last-child {
        margin: 0;
    }
}

@media (min-width: 1200px) {
    .menu-1-outer-container {
        padding: 1.5% 0 0;
    }
}