@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* 
FONTS

    font-family: 'Oswald', sans-serif;
    font-family: 'Montserrat', sans-serif;

*/

.display-5-container {
    margin: 12.5% 0 0;
    font-family: 'Montserrat', sans-serif;
    max-width: 100vw;
    overflow-x: hidden;
    overflow: hidden;
}

.display-5-container-background-filler {
    position: absolute;
    margin: 5% -100% 0;
    width: 400%;
    height: 50vh;
    rotate: -45deg;
    background-color: #F2B807;
    opacity: .7;
    z-index: -1;
    overflow: hidden;
    display: none;
}

.display-5-inner-container {
    padding: 0 10%;
    /* border-style: solid; */
    /* border-color: #cbd6e0; */
    max-width: 100vw;
    overflow-x: hidden;
}

.display-5-title-context {
    font-family: 'Oswald', sans-serif;
    font-size: .9rem;
    /* font-weight: 400; */
    font-weight: bold;
    margin: 0 0 4%;
    color: #455973;
}

.display-5-title {
    font-family: 'Oswald', sans-serif;
    font-size: 2.25rem;
    font-weight: 500;
    max-width: 95%;
    margin: 0 0 12%;
    color: #A63333;
}

.display-5-statement-container {}

.display-5-statement {
    color: #425466;
}

.display-5-statement-icon {
    width: 10%;
    margin: 0 0 4%;
}

.display-5-statement-title {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    margin: 0 0 4%;
    border-left-style: solid;
    border-left-color: #A63333;
    margin-left: -5%;
    padding-left: 5%;
    /* color: #0a2540; */
    color: #A63333;
}

.display-5-statement-statement {
    line-height: 1.6;
    margin: 0 0 12%;
}

.display-5-statement-statement-highlight {
    color: #A63333;
    font-weight: 500;
}

@media (min-width: 600px) {
    .display-5-container-background-filler {
        display: none;
    }

    .display-5-inner-container {
        padding: 0 2rem;
    }

    .display-5-statement-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .display-5-title-context {
        font-size: 1rem;
    }

    .display-5-title {
        font-size: 2.75rem;
    }

    .display-5-statement {
        width: 45%;
    }

    .display-5-statement-icon {
        width: 17.5%;
        margin: 0 0 8%;
    }

    .display-5-statement-title {
        margin: 0 0 8% -5%;
    }

    .display-5-statement-statement {
        margin: 0 0 24%;
    }
}

@media (min-width: 775px) {
    .display-5-title {
        font-size: 3rem;
    }
}

@media (min-width: 900px) {
    .display-5-title {
        margin: 0 0 7.5%;
        max-width: 75%;
    }

    .display-5-statement {
        width: 20%;
        font-size: .9rem;
    }

    .display-5-statement-title {
        font-size: 1rem;
    }

    .display-5-statement-statement {
        line-height: 1.7;
    }
}

@media (min-width: 1125px) {
    .display-5-inner-container {
        max-width: 1125px;
        margin: 0 auto;
    }

    .display-5-title-context {
        margin: 0 0 2%;
    }
}
