* {
    box-sizing: border-box;
}

form {
    background-color: beige;

}

input {
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #444;
    width: 45%;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    /* margin-right: 4.5%; */
}

input:nth-of-type(odd) {
    margin-right: 10%;
}

#dropOffDate {
    margin-right: 10%;
}
