@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.contact-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
}

.contact-title {
    font-family: "Montserrat";
    color: #A63333;
    text-align: center;
}

.contact-phone-number,
.contact-email {
    font-family: "Oswald";
    font-size: 1.25rem;
    text-align: center;
    color: #455973;
}

.contact-phone-number {}

.contact-email {}
