@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

/* 
FONTS
    font-family: 'Poppins', sans-serif;
    font-family: 'Oswald', sans-serif;
*/

.display-7-outer-container {
    overflow: hidden;
    margin: 3% 0 0;
    font-family: 'Poppins', sans-serif;
    padding: 3%;
    text-align: center;
}

.display-7-inner-container {
    max-width: 1000px;
    margin: 0 auto;
}

.display-7-main-container {}

.display-7-main-left-section {
    display: flex;
    flex-direction: column;
}

.display-7-main-left-section-icon {
    width: 15%;
    margin: 0 auto;
}

.display-7-main-left-section-title {
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin: 4% 0 0;
    color: #A63333;
}

.display-7-main-left-section-statement {
    margin: 4% 0 0;
    line-height: 1.8;
    color: #455973;
}

.display-7-main-right-section{
    margin: 7.5% 0 0; /* re-adjust for larger screens... remove bottom margin */
    border-radius: 8px;
}

.display-7-main-right-section-image {
    width: 100%;
    border-radius: 8px;
}

.display-7-secondary-container {
    display: flex;
    flex-direction: column;
    margin: 25% 0 0;
}

.display-7-secondary-container-item {
    text-align: center;
    margin: 25% 0 0;
}

.display-7-secondary-container-item:first-child {
    margin: 0 0;
}

.display-7-item-icon {
    width: 20%;
}

.display-7-item-title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    margin: 5% 0 0;
    color: #A63333;
}

.display-7-item-statement {
    width: 80%;
    margin: 5% auto 0;
    line-height: 1.8;
    color: #455973;
}

.faq-contact-link {
    color: #455973;
    text-decoration: none;
    font-weight: bold;
}

@media (min-width: 600px) {
    .display-7-main-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .display-7-main-left-section {
        width: 47%;
    }

    .display-7-main-right-section {
        width: 47%;
        margin: 0 0;
    }

    .display-7-main-right-section-image {
        max-height: 500px;
        object-fit: contain;
    }

    .display-7-secondary-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 15% 0 0;
    }

    .display-7-secondary-container-item {
        width: 50%;
        margin: 15% 0 0;
    }

    .display-7-secondary-container-item:first-child {
        margin: 15% 0 0;
    }

    /* Targets first two DOM elements in flexbox */
    .display-7-secondary-container-item:nth-child(-n+2) {
        margin: 0;
    }
}


/*
    .display-7-secondary-container-item:nth-last-child(-n+3) {
        margin-top: 15%;
    } 
*/