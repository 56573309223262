@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* 
FONTS

    font-family: 'Oswald', sans-serif;
    font-family: 'Montserrat', sans-serif;

*/

.display2-container {
    margin: 15% 7.5% 0;
    /* margin: 0; */
    padding: 0;
    background-color: #fff;
}

.display2-title {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 0 7.5%;
    color: #A63333;
}

.display2-cards-container {}

.display2-cards-card {
    margin: 10% 0;
    padding: 15% 0;
    text-align: center;
    background-color: #f8fafb;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.display2-card-icon {
    width: 10%;
    margin: 0 0;
}

.display2-card-title {
    font-family: 'Oswald', sans-serif;
    margin-bottom: 2.5%;
    font-size: 1.75rem;
    font-weight: 700;
    color: #A63333;
}

.display2-card-statement {
    font-family: 'Montserrat';
    font-size: .9rem;
    max-width: 80%;
    margin: 0 auto;
    line-height: 1.7;
    letter-spacing: .4px;
    color: #455973;
}

.display2-end-statement {
    margin: 0 0 10%;
    color: #455973;
    text-align: center;
    line-height: 1.7;
}

.display2-btn-container {
    display: flex;
    flex-direction: column;
}

.display2-btn {
    border: none;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: .85rem 0;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    background-color: #A63333;
    color: #f8fafb;
    letter-spacing: .5px;
}

.display2-btn:first-child {
    margin: 0 0 7.5%;
}

@media (min-width: 500px) {
    .display2-title {
        font-size: 3rem;
    }
}

@media (min-width: 575px) {
    .display2-cards-card,
    .display2-end-statement,
    .display2-btn-container {
        max-width: 460px;
        margin: 10% auto;
    }
}

@media (min-width: 725px) {
    .display2-container {
        margin: 0 2.5%;
    }

    .display2-title {
        font-size: 3.75rem;
        margin: 0 0 2.5%;
    }

    .display2-cards-container {
        display: flex;
        flex-wrap: wrap;
    }

    .display2-cards-card,
    .display2-end-statement,
    .display2-btn-container {
        margin: 2.5% auto;
    }

    .display2-cards-card {
        width: 45%;
    }

    .display2-end-statement {
        font-size: 1rem;
        max-width: 689px;
        width: 100%;
        line-height: 1.9;
        margin: 2.5% auto 5%;
    }

    .display2-btn-container {}

    .display2-btn {
        border-radius: 12px;
    }
}

@media (min-width: 1000px) {
    .display2-cards-card {
        width: 29%;
        padding: 10% 0%;
    }
}

@media (min-width: 1100px) {
    .display2-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .display2-end-statement {
        margin: 2.5% auto;
    }

    .display2-btn {
        width: 372px;
    }
}

@media (min-width: 1200px) {
    .display2-title {
        font-size: 4.5rem;
    }
}
