@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

.home-page-hero-outer-container {
    font-family: 'Oswald', sans-serif;
    display: none;
}

.home-page-hero-title {
    color: #A63333;
    text-align: center;
    font-size: 5rem;
}

.home-page-hero-title-image {
    width: 33%;
    margin: 0 auto 4%;
}

.home-page-hero-title-statement {
    color: #455973;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    margin: -4% 0 0;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .5px;
}

.home-page-hero-image {
    width: 50%;
    border-radius: 8px;
}

/* .home-page-hero-background-filler {
    position: absolute;
    margin: 10vh 0 0 -105%;
    z-index: -2;
    width: 400vw;
    height: 50vh;
    rotate: 17deg;
    background-color: #F2F2F2;
    overflow: hidden;
    display: none;
} */

@media (min-width: 920px) {
    /* .home-page-hero-background-filler {
        display: none;
    } */
}

@media (min-width: 920px) {
    .home-page-hero-outer-container {
        display: flex;
        justify-content: center;
        margin: 5% 0 0;
    }

    .home-page-hero-title-title-statement-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
    }

    .home-page-hero-btn-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 4% 0 0;
    }

    .home-page-hero-rent-a-dumpster-btn,
    .home-page-hero-contact-us-btn {
        background-color: #A63333;
        color: #ffffff;
        border-radius: 4px;
        padding: .5rem 1rem;
        font-size: 1.25rem;
        font-weight: 400;
        text-decoration: none;
    }

    .home-page-hero-rent-a-dumpster-btn {
        margin-right: 4%;
    }

    .home-page-hero-contact-us-btn {

    }
}

@media (min-width: 1350px) {
    .home-page-hero-title-title-statement-container,    
    .home-page-hero-image {
        width: 45%;
    }
}