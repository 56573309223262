@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&display=swap');

/* 
FONTS
    font-family: 'Montserrat', sans-serif;
    font-family: 'Oswald', sans-serif;
*/

.display4-container {
    font-family: 'Montserrat', sans-serif;
    background-color: #f6f9fc;
    width: 100%;
    margin: 0;
    padding: 20% 5%;
    border-radius: 0;
}

.display4-container-left-right-container {}

.display4-title-context {
    font-size: .9rem;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    letter-spacing: .5px;
    color: #707f98;
}

.display4-title {
    text-align: left;
    margin: 5% 0;
    /* max-width: 30%; */
    font-size: 3rem;
    color: #0a2540;
}

.display4-statement-container {}

.display4-statement {
    font-size: 1.05rem;
    line-height: 1.9;
    color: #425466;
    font-weight: 500;
    margin: 0 0 10%;
}

.display4-statement:last-child {
    margin: 0;
}

.display4-statement-placeholder-image {
    width: 100%;
    border-radius: 6px;
}

.display4-btn {
    border: none;
    border-radius: 30px;
    padding: .6rem 1rem;
    font-size: .9rem;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
    margin: 10% 0;
    max-width: 65%;
}

.display4-btn:hover {
    cursor: pointer;
}

.display4-btn-right-arrow-icon {
    margin: 0 0 0 3%;
    width: 6%;
}


@media (min-width: 600px) {
    .display4-container {
        padding: 12.5% 5%;
    }

    .display4-statement-container{
        display: flex;
    }

    .display4-title {
        margin: 2.5% 0;
    }

    .display4-statement {
        max-width: 45%;
    }

    .display4-statement:first-child {
        margin: 0 5% 0 0;
    }

    .display4-btn {
        margin: 5% 0 0;
        max-width: 35%;
    }
}

@media (min-width: 725px) {
    .display4-btn-right-arrow-icon {
        width: 5%;
    }
}

@media (min-width: 850px) {
    .display4-btn-right-arrow-icon {
        width: 4%;
    }
}

@media (min-width: 900px) {
    .display4-title {
        margin: 2% 0;
    }
}

@media (min-width: 1100px) {
    .display4-container {
        /* max-width: 1100px; */
        padding: 10% 5%;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        margin: 0 auto;
    }

    .display4-container-left-right-container {
        max-width: 1100px;
        padding: 10% 5%;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        margin: 0 auto;
    }

    .display4-container-inner-container {}

    .display4-statement-placeholder-image {
        width: 50%;
        border-radius: 8px;
    }

    .display4-title-context {
        align-self: self-start;
    }

    .display4-title {
        min-width: 65%;
        align-self: self-start;
    }

    .display4-statement-container {
        max-width: 1170px;
        align-self: self-start;
    }

    .display4-statement {
        min-width: 87.5%;
        width: 100%;
    }

    .display4-btn {
        align-self: self-start;
    }

    .display4-btn-right-arrow-icon {
        width: 5%;
    }
}

@media (min-width: 1200px) {
    .display4-container {
        padding: 8% 5%;
    }

    .display4-container-inner-container {
        max-width: 1150px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1500px) {
    .display4-container {
        padding: 6% 5%;
    }
}