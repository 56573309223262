@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&display=swap');

/* 
FONTS
    font-family: 'Montserrat', sans-serif;
    font-family: 'Oswald', sans-serif;
*/

.display3-container {
    font-family: 'Montserrat', sans-serif;
    background-color: #f6f9fc;
    width: auto;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 20% 5%;
    border-radius: 0;
}

.display3-title-context {
    font-size: .9rem;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    letter-spacing: .5px;
    color: #707f98;
}

.display3-title {
    font-family: 'Oswald', sans-serif;
    text-align: left;
    margin: 5% 0;
    max-width: 344px;
    font-size: 3rem;
    color: #0a2540;
}

.display3-statement-container {
    margin: 0 0 10%;
}

.display3-statement {
    font-size: 1.05rem;
    line-height: 1.9;
    color: #425466;
    font-weight: 500;
    margin: 0 0 10%;
}

.display3-statement:last-child {
    margin: 0;
}

.display3-btn {
    border: none;
    border-radius: 30px;
    padding: .75rem 2.5rem;
    font-size: .9rem;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
    margin: 10% 0 0;
    /* max-width: 65%; */
    background-color: #0a2540;
    color: #fff;
    text-decoration: none;
}

.display3-btn:hover {
    cursor: pointer;
}

.display3-btn-right-arrow-icon {
    margin: 0 0 0 3%;
    width: 3%;
}


@media (min-width: 600px) {
    .display3-container {
        padding: 12.5% 5%;
    }

    .display3-statement-container{
        display: flex;
        /* justify-content: space-between; */
    }

    .display3-title {
        margin: 2.5% 0;
    }

    .display3-statement {
        max-width: 45%;
    }

    .display3-statement:first-child {
        margin: 0 5% 0 0;
    }

    .display3-statement:last-child {
        max-width: 42.5%;
    }

    .display3-btn {
        margin: 5% 0 0;
        max-width: 35%;
    }
}

@media (min-width: 725px) {
    .display3-btn-right-arrow-icon {
        width: 5%;
    }
}

@media (min-width: 850px) {
    .display3-btn-right-arrow-icon {
        width: 4%;
    }
}

@media (min-width: 900px) {
    .display3-title {
        margin: 2% 0;
    }

    .display3-statement:last-child {
        max-width: 37.5%;
    }
}

@media (min-width: 1100px) {
    .display3-container {
        min-width: 100vw;
        padding: 10% 5%;
    }

    .display3-title-context {
        align-self: self-start;
    }

    .display3-title {
        align-self: self-start;
    }

    .display3-statement-container {
        max-width: 1170px;
        align-self: self-start;
    }

    .display3-btn {
        align-self: self-start;
        max-width: 25%;
    }

    .display3-btn-right-arrow-icon {
        width: 5%;
    }
}

@media (min-width: 1200px) {
    .display3-container {
        padding: 8% 5%;
    }

    .display3-container-inner-container {
        max-width: 1150px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1500px) {
    .display3-container {
        padding: 6% 5%;
    }
}