@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

/* 
FONTS
    font-family: 'Poppins', sans-serif;
    font-family: 'Oswald', sans-serif;
*/

.display-6-outer-container {
    font-family: 'Poppins', sans-serif;
}

.display-6-inner-container {
    padding: 4% 4% 0;
    max-width: 950px;
    margin: 0 auto;
}

.display-6-cards-container {}

.display-6-card-container {
    margin: 0 0 16%;
}

.display-6-card-icon-title-container {
    display: flex;
    align-items: center;
    margin: 0 0 5%;
}

.display-6-card-icon {
    width: 4rem;
    margin: 0 2% 0 0;
}

.display-6-card-title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    font-weight: 1.5rem;
    color: #353839;
}

.display-6-card-statement {
    color: #474747;
    line-height: 1.9;
}

.display-6-btn-container {
    margin: 0 auto;
    max-width: 950px;
}

.display-6-link {}

.display-6-btn{
    font-family: 'Oswald', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: .5px;
    color: #222;
    background-color: #efefef;
    max-width: 950px;
    width: 100%;
    border: none;
    padding: 4% 0;
}

@media (min-width: 600px) {
    .display-6-cards-container {
        display: flex;
        justify-content: space-between;
    }

    .display-6-card-container {
        max-width: 46%;
        margin: 0 0 8%;
    }
}

@media (min-width: 1024px) {
    .display-6-inner-container {
        padding: 0;
    }

    .display-6-btn {
        font-size: 1.2rem;
        border-radius: 5px;
    }
}
