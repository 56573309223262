@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* 
FONTS

    font-family: 'Oswald', sans-serif;
    font-family: 'Montserrat', sans-serif;

*/

.display-container {
    margin: 0 3.333%;
    padding: 10% 3.333%;
    background-color: #f8fafb;
    color: #0b2239;
    border-radius: 8px;
}

.display-section {
    padding: 0 10%;
    text-align: center;
}

.display-section-title-statement {
    line-height: 1.6;
}

.display-section-title-statement-title {
    font-size: 3rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
}

.display-section-title-statement-statement {
    color: #374957;
    margin: 2.5% 0 20% 0;
}

.display-section-title {
    font-size: 3rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
}

.display-section-statement {
    color: #374957;
    margin: 2.5% 0 0;
    line-height: 1.6;
}

@media (min-width: 425px) {
    .display-section-title-statement-statement,
    .display-section-statement {
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 650px) {
    .display-section-title-statement-title,
    .display-section-title {
        font-size: 3.5rem;
    }

    .display-section-title-statement-statement,
    .display-section-statement {
        font-size: 1.15rem;
    }
}

@media (min-width: 900px) {
    .display-section {
        padding: 0;
    }

    .display-section:first-child {
        display: flex;
        justify-content: center;
    }

    .display-section-title-statement {
        margin: 0 5%;
    }

    .display-section-statement {
        max-width: 675px;
    }
}

@media (min-width: 1100px) {
    .display-container {
        display: flex;
        align-items: center;
        padding: 3.333% 0;
    }

    .display-section:first-child {
        display: block;
        width: 50%;
    }

    .display-section-title-statement:first-child {
        margin: 0 0 7.5%;
    }

    .display-section-title-statement-statement {
        margin: 0 auto 20%;
    }

    .display-section-title-statement-statement:last-child {
        margin: 0 auto 0;
    }

    .display-section-statement {
        width: 65%;
    }
}

@media (min-width: 1300px) {
    .display-container {
        max-width: 1300px;
        margin: 0 auto;
    }
}
